import { useState } from 'react';
import { Button, FormControlLabel, Radio, TextField, FormControl, IconButton, RadioGroup, Typography, Grid, Divider, Select  } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from '../../styles';
import ContentWrapper from 'components/ContentWrapper';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Row from './Row';
import ReactHookTextField from '../../../../components/ReactHookTextField';
import { pdf } from '@react-pdf/renderer';
import PDF from './PDF';
import { saveAs } from 'file-saver';
import { axiosInstance } from 'App';
import NumberFormat from '../../../../components/NumberFormat';
import { QuoteFields, quoteDefaultValues, quoteFormValidation, salesRepInitials } from '../../components/QuoteFields';
import Header from '../Header';
import Files from 'components/Files';
import CreateJob from 'components/CreateJob';
import { useAuth } from 'providers/auth';

export const formValidation = Yup.object().shape({
    ...quoteFormValidation.fields,
    lineItems: Yup.array().of(Yup.object().shape({
        product: Yup.object().shape({ id: Yup.number().transform(value => (isNaN(value) ? undefined : value)).required('Product selection is required') }),
        quantity: Yup.number().transform(value => (isNaN(value) ? undefined : value)).required('Qty is required'),
        attributes:  Yup.object().shape({ 
            oemPrice: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
            listPrice: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        }),
      })),
      freightCost: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
      totalPrice: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
      validity: Yup.string().required('Validity required'),
});

const Form = ({ quoteData, quoteDetails, itemList, revList, clientList, quoteStatusList, salesRepList, productCategoryList, productAreaList, enquirySourceList, industryList, applicationList, blowers, blowersObj }) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { userAttr } = useAuth();
    const { quoteID, itemID, revision } = quoteData;

    const [totalCost, setTotalCost] = useState(quoteDetails.lineItems.map(e => e.quantity * (quoteDetails.attributes.isOemPrice ? e.attributes.oemPrice : e.attributes.listPrice)))

    const { handleSubmit, watch, control, setError, setValue, getValues } = useForm({
        resolver: yupResolver(formValidation),
        defaultValues: {
            ...quoteDefaultValues(quoteData),
            attributes: {
                ...quoteDetails.attributes,
                isOemPrice: quoteDetails.attributes !== null ? quoteDetails.attributes?.isOemPrice ? "true" : "false" : "true",
                deliveryTerm: quoteDetails.attributes?.deliveryTerm === null || quoteDetails.attributes?.deliveryTerm === undefined ? "Ex. Works Melbourne" : quoteDetails.attributes?.deliveryTerm,
                extraText: quoteDetails.attributes?.extraText,
                leadTime: quoteDetails.attributes?.leadTime === null || quoteDetails.attributes?.deliveryTerm === undefined ? "Ex. Stock" : quoteDetails.attributes?.leadTime,
            },
            validity: quoteDetails.validity !== null ? quoteDetails.validity : "30 Days Nett",
            lineItems: quoteDetails.lineItems.length ? quoteDetails.lineItems : 
                [{
                    product: { id: '' },
                    quantity: '',
                    comments: ''
                }],
            freightCost: quoteDetails.freightCost,
            updatedAt: quoteDetails.updatedAt
        } 
      });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "lineItems"
      });

    const watchIsOemPrice = watch("attributes.isOemPrice", quoteDetails.attributes && quoteDetails.attributes.isOemPrice ? "true" : "false"); 
    
    const watchFreightCost = watch("freightCost"); 

    const calcTotal = () => totalCost.reduce((a, b) => a + b, 0) + (isNaN(watchFreightCost) ? 0 : parseInt(watchFreightCost));

    const onSubmit = async data => {
        try {
            const response = await makePost({
                url: `/quotedetails/${quoteID}/${itemID}/${revision}/scblowers`,
                data: { 
                    ...data,
                    attributes: { 
                        ...data.attributes,
                        isOemPrice: data.attributes.isOemPrice === "true",
                    },
                    totalPrice: calcTotal(),
                    combinedPrice: calcTotal()
                },
            });

            if (response.status === 200) {
                setValue('updatedAt', response.data.updatedAt)
            }

            dispatch(addFeedback('Products saved to quote item', 'success'));
        } catch (error) {
            const errData = error.response.data;

            if (errData === "Stale update. Reload and update again") {
                dispatch(addFeedback(errData, 'error'));
            } else {
                Object.keys(errData.errors).map((key) => {
                    setError(key.slice(2), errData.errors[key][0]);
                    return null;
                });
    
                dispatch(addFeedback(errData.title || errData, 'error', errData.errors));
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Header quoteData={quoteData} itemList={itemList} revList={revList} productCategoryList={productCategoryList} getValues={getValues} />

            <ContentWrapper>
                <QuoteFields
                    control={control}
                    clientList={clientList}
                    quoteStatusList={quoteStatusList}
                    salesRepList={salesRepList}
                    productAreaList={productAreaList}
                    enquirySourceList={enquirySourceList}
                    industryList={industryList}
                    applicationList={applicationList}
                    watch={watch} 
                    setValue={setValue} />
            </ContentWrapper>

            <Typography variant="h2">
                Side Channel Blowers
            </Typography>

            <ContentWrapper>
                <FormControl component="fieldset" style={{width: '100%'}}>
                    <Controller
                        name="attributes.isOemPrice"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                row
                                aria-label="isOemPrice"
                                value={field.value}
                                onBlur={field.onBlur}
                                onChange={(e) => {
                                    field.onChange(e);
                                }}
                            >
                                <FormControlLabel value="true" control={<Radio />} label="OEM Price" />
                                <FormControlLabel value="false" control={<Radio />} label="List Price" />
                            </RadioGroup>
                        )}
                    />
                </FormControl>

                                
                { !fields.length ? 
                    <Button
                        variant='contained'
                        color='primary'
                        type="button"
                        onClick={() => append({
                            product: { id: '' },
                            quantity: '',
                            comments: ''
                        })}
                    >
                        Add a Side Channel Blower
                    </Button> :
                    <>
                        {fields.map((field, index) => 
                            <Row key={`row-${index}`} control={control} index={index} field={field} watchIsOemPrice={watchIsOemPrice} blowers={blowers} blowersObj={blowersObj} setTotalCost={setTotalCost} totalCost={totalCost} setValue={setValue}/> 
                        )}
                        
                        <div style={{textAlign: 'right'}}>
                            <IconButton
                                type="button"
                                color="secondary"
                                onClick={() => {
                                    setTotalCost(totalCost.slice(0, fields.length-1));
                                    return remove(fields.length-1);
                                }}
                                style={{width: '4%'}}
                                >
                                <RemoveIcon />
                            </IconButton>
                            
                            <IconButton
                                type="button"
                                color="secondary"
                                onClick={() => append({
                                    product: { id: '' },
                                    quantity: '',
                                    comments: ''
                                })}
                                style={{width: '4%'}}
                                >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </>
                }
            
                <Divider />
                <br />

                <ReactHookTextField
                    name='freightCost'
                    type='text'
                    control={control}
                    label='Freight Cost'
                    style={{width: '19%', marginRight: '51.5%'}}
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                />

                <TextField
                    label='Total Price'
                    type='text'
                    disabled={true}
                    className={classes.disabledInput}
                    variant='outlined' 
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                    value={calcTotal()}
                    style={{width: '19%'}}
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                />
            </ContentWrapper>

            <ContentWrapper>
                <ReactHookTextField
                    name='attributes.extraText'
                    type='textarea'
                    control={control}
                    label='Extra Text'
                    style={{width: '100%'}}
                    InputProps={{
                        multiline:true
                    }}
                />

                <ReactHookTextField
                    name='validity'
                    type='text'
                    control={control}
                    label='Validity'
                    style={{width: '24%', marginRight: '1%'}}
                />

                <ReactHookTextField
                    name='attributes.leadTime'
                    type='text'
                    control={control}
                    label='Lead Time'
                    style={{width: '24%', marginRight: '1%'}}
                />

                <ReactHookTextField
                    name='attributes.deliveryTerm'
                    type='text'
                    control={control}
                    label='Delivery Term'
                    style={{width: '24%', marginRight: '1%'}}
                />
            </ContentWrapper>

            <Grid container justifyContent="space-between">
                <Grid>
                    { userAttr.roles.includes("Admin") && <>
                        <Typography variant="h1" className={classes.header} style={{ display: 'inline' }}>State: </Typography>
                        <Controller
                            name='quoteStateID'
                            type={'select'}
                            control={control}
                            render={({ field, fieldState: { error } }) =>
                                <FormControl variant='outlined' className={classes.select}>
                                    <Select
                                        native
                                        {...field}
                                        margin='dense'
                                        onChange={(e) => {
                                            return field.onChange(e.target.value)
                                        }}>
                                            <option key='state-1' value='1'>Open</option>
                                            <option key='state-2' value='2'>Archive</option>
                                    </Select>
                                </FormControl>
                            }
                        />
                    </>}
                </Grid>
                <Grid>
                    <Button
                        variant='contained'
                        color='secondary'
                        type='submit'
                    >
                        Save Quote 
                    </Button>
                    &nbsp;
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        onClick={async () => {
                            const quoteRes = await axiosInstance({ url: `/quotes/${quoteID}/${itemID}/${revision}` });
                            const quoteDetailsRes = await axiosInstance({ url: `/quotedetails/${quoteID}/${itemID}/${revision}/scblowers` });
                            const quoteData = quoteRes.data;
                            
                            const blob = await pdf((
                                <PDF quoteData={quoteData} quoteDetails={quoteDetailsRes.data} />
                            )).toBlob();

                            const initials = quoteData.salesRepName ? salesRepInitials(quoteData.salesRepName) : 'NoSalesRep';
                                
                            saveAs(blob, `${quoteID}${revision}${itemID}${initials}.pdf`);
                        }}
                    >
                        Generate PDF 
                    </Button>
                    &nbsp;
                    <CreateJob url={`/jobs/new/${quoteID}/${revision}/${itemID}`} />
                </Grid>
            </Grid>
            <br/>

            <Typography variant="h2">
                Files
            </Typography>

            <ContentWrapper style={{display: 'flex'}}>
                <Files url={`/quotes/${quoteID}/${itemID}/${revision}/files`} />
            </ContentWrapper>
        </form>
    );
};

export default Form;
