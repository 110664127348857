import { useState } from 'react';
import { Button, TextField, Typography, Grid, FormControl, Select } from '@material-ui/core';
import { useStyles } from '../../styles';
import ContentWrapper from 'components/ContentWrapper';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from "react-router-dom"
import { QuoteFields, quoteDefaultValues, quoteFormValidation, salesRepInitials } from '../../components/QuoteFields';
import Header from '../Header';
import NumberFormat from '../../../../components/NumberFormat';
import { saveAs } from 'file-saver';
import { axiosInstance } from 'App';
import Files from 'components/Files';
import ReactHookTextField from 'components/ReactHookTextField'
import CreateJob from 'components/CreateJob';
import queryString from 'query-string';
import fanSizePlusMinus from 'helpers/fanSizePlusMinus';
import { useAuth } from 'providers/auth';

const Form = ({ quoteData, quoteDetails, itemList, revList, clientList, quoteStatusList, salesRepList, productCategoryList, productAreaList, enquirySourceList, industryList, applicationList }) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { search } = useLocation()
    const { userAttr } = useAuth();
    let { clone } = queryString.parse(search)
    // Make some fields editable based on ?clone. If passed clone === null
    let isClone = clone === null

    const { quoteID, itemID, revision } = quoteData;
    const [fanData, setFanData] = useState(quoteDetails.attributes || {});

    const { handleSubmit, watch, control, setError, getValues, setValue } = useForm({
        resolver: yupResolver(quoteFormValidation),
        defaultValues: {
            ...quoteDefaultValues(quoteData),
            attributes: {
                ...quoteDetails.attributes,
            },
            updatedAt: quoteData.updatedAt
        }
    });

    const onSubmit = async data => {
        try {
            const response = await makePost({
                url: `/quotes/${quoteID}/${itemID}/${revision}`,
                data: {
                    ...data,
                    productCategoryID: quoteData.productCategoryID,
                },
            });

            if (response.status === 200) {
                setValue('updatedAt', response.data.updatedAt)
            }

            dispatch(addFeedback('Quote saved', 'success'));
        } catch (error) {
            const errData = error.response.data;

            if (errData === "Stale update. Reload and update again") {
                dispatch(addFeedback(errData, 'error'));
            } else {
                Object.keys(errData.errors).map((key) => {
                    setError(key.slice(2), errData.errors[key][0]);
                    return null;
                });

                dispatch(addFeedback(errData.title || errData, 'error', errData.errors));
            }
        }
    };

    const onCenFileChange = async event => {
        const formData = new FormData();

        formData.append(
            "file",
            event.target.files[0],
            event.target.files[0].name
        );

        const response = await makePost({
            url: `/quotedetails/${quoteID}/${itemID}/${revision}/centrixfans`,
            data: formData,
        });

        if (response.status === 200) {
            setValue('updatedAt', response.data.updatedAt)
            setValue('combinedPrice', response.data.combinedPrice)
            setValue('combinedCost', response.data.combinedCost)
            setFanData(response.data.attributes)
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Header quoteData={quoteData} itemList={itemList} revList={revList} productCategoryList={productCategoryList} getValues={getValues} />

            <ContentWrapper>
                <QuoteFields
                    control={control}
                    clientList={clientList}
                    quoteStatusList={quoteStatusList}
                    salesRepList={salesRepList}
                    productAreaList={productAreaList}
                    enquirySourceList={enquirySourceList}
                    industryList={industryList}
                    applicationList={applicationList}
                    watch={watch}
                    setValue={setValue} />
            </ContentWrapper>

            <Typography variant="h2">
                Centrifugal Fan
            </Typography>

            <ContentWrapper>
                <Button
                    variant="contained"
                    color='primary'
                    component="label"
                >
                    Upload File
                    <input
                        type="file"
                        hidden
                        onChange={onCenFileChange}
                    />
                </Button>
                <br />
                <br />
                <TextField
                    label='File'
                    type='text'
                    disabled={true}
                    className={classes.disabledInput}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    value={fanData.filename}
                    style={{ width: '15%', marginRight: '0.5%' }}
                />
                <TextField
                    label='Design'
                    type='text'
                    disabled={true}
                    className={classes.disabledInput}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    value={fanData.fanDesign}
                    style={{ width: '15%', marginRight: '0.5%' }}
                />
                <TextField
                    label='Size'
                    type='text'
                    disabled={true}
                    className={classes.disabledInput}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    value={fanData.fanSize + fanSizePlusMinus(fanData.fanSizePlusMinus)}
                    style={{ width: '10%', marginRight: '0.5%' }}
                />
                <TextField
                    label='Qty'
                    type='text'
                    disabled={true}
                    className={classes.disabledInput}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    value={fanData.fanQty}
                    style={{ width: '5%', marginRight: '0.5%' }}
                />
                <ReactHookTextField
                    name='combinedPrice'
                    type='text'
                    control={control}
                    label='Combined Price'
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                    style={{ width: '16%', marginRight: '1%' }}
                />
                <ReactHookTextField
                    name='combinedCost'
                    type='text'
                    control={control}
                    label='Combined Cost'
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                    style={{ width: '16%', marginRight: '1%' }}
                />
            </ContentWrapper>

            <Grid container justifyContent="space-between">
                <Grid>
                    { userAttr.roles.includes("Admin") && <>
                        <Typography variant="h1" className={classes.header} style={{ display: 'inline' }}>State: </Typography>
                        <Controller
                            name='quoteStateID'
                            type={'select'}
                            control={control}
                            render={({ field, fieldState: { error } }) =>
                                <FormControl variant='outlined' className={classes.select}>
                                    <Select
                                        native
                                        {...field}
                                        margin='dense'
                                        onChange={(e) => {
                                            return field.onChange(e.target.value)
                                        }}>
                                            <option key='state-1' value='1'>Open</option>
                                            <option key='state-2' value='2'>Archive</option>
                                    </Select>
                                </FormControl>
                            }
                        />
                    </>}
                </Grid>
                <Grid>
                    <Button
                        variant='contained'
                        color='secondary'
                        type='submit'
                    >
                        Save Quote
                    </Button>
                    &nbsp;
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                            handleSubmit(onSubmit)().then(async () => {
                                const quoteRes = await axiosInstance({ url: `/quotes/${quoteID}/${itemID}/${revision}` });
                                const quoteData = quoteRes.data;
                                const initials = salesRepInitials(quoteData.salesRepName);
                                var blob = new Blob([
                                    `[frmProject] \n` +
                                    `txtCustomerName=${quoteData.clientDetails.name} \n` +
                                    `txtQuoteRef=${quoteID}${revision}${itemID}${initials} \n` +
                                    `txtItemRef=${quoteData.itemRef} \n` +
                                    `txtItemNum=${itemID} \n` +
                                    `txtProject=${quoteData.project}`
                                ], { type: "text/plain;charset=utf-8" });
                                saveAs(blob, `${quoteID}${revision}${itemID}${initials}.ceninp`);
                            })
                        }}
                    >
                        Centrix Export
                    </Button>
                    &nbsp;
                    <CreateJob url={`/jobs/new/${quoteID}/${revision}/${itemID}`} />
                </Grid>
            </Grid>
            <br/>

            <Typography variant="h2">
                Files
            </Typography>

            <ContentWrapper style={{ display: 'flex' }}>
                <Files url={`/quotes/${quoteID}/${itemID}/${revision}/files`} />
            </ContentWrapper>
        </form>
    );
};

export default Form;
