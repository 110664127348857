import * as Yup from 'yup';
import ReactHookTextField from '../../../components/ReactHookTextField';
import SelectWithDataReactHook from '../../../components/SelectWithDataReactHook';
import ClientContactsAndAddressesHook from './ClientContactsAndAddressesReactHook';

export const salesRepInitials = name => name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'');
export const displayedDueDate = (dueDate, revisedDueDate) => {

    dueDate = revisedDueDate ?? dueDate;
    return new Date(dueDate).toLocaleDateString("en-AU", { day: '2-digit', month: '2-digit', year: '2-digit' })
}

export const quoteFormValidation = Yup.object().shape({
    clientID: Yup.number().typeError('Client must be selected').required('Client required'),
    contactID: Yup.number().typeError('Client Contact must be selected').required('Client Contact required'),
    addressID: Yup.number().typeError('Client Address must be selected').required('Client Address required'),
    createdAt: Yup.string().required('Created Date required'),
    awardedAt: Yup.string().required('Award Date required'),
    salesRepID: Yup.string().required('Sales Rep required'),
    quoteStatusID: Yup.number().typeError('Quote Status must be selected').required('Quote Status required'),
    productAreaID: Yup.number().typeError('Product Area must be selected').required('Product Area required'),
    enquirySourceID: Yup.number().typeError('Enquiry Source must be selected').required('Enquiry Source required'),
});

export const quoteDefaultValues = (quoteData) => {
    return {
    clientID: String(quoteData.clientID),
    contactID: quoteData.contactID,
    addressID: quoteData.addressID, 
    clientDetails: quoteData.clientDetails,
    createdAt: quoteData.createdAt.slice(0, 10),
    awardedAt: quoteData.awardedAt.slice(0, 10),
    salesRepID: quoteData.salesRepID,
    enquirySourceID: String(quoteData.enquirySourceID),
    productAreaID: String(quoteData.productAreaID),
    quoteStatusID: String(quoteData.quoteStatusID),
    quoteStateID: quoteData.quoteStateID,
    project: quoteData.project,
    fanSerial: quoteData.fanSerial,
    itemRef: quoteData.itemRef,
    comments: quoteData.comments,
    combinedCost: quoteData.combinedCost,
    combinedPrice: quoteData.combinedPrice,
    industryID: quoteData.industryID ? String(quoteData.industryID) : null,
    applicationID: quoteData.applicationID ? String(quoteData.applicationID) : null,
}};

export const QuoteFields = ({ control, clientList, salesRepList, productAreaList, quoteStatusList, enquirySourceList, industryList, applicationList, watch, setValue }) => {
    
    return (
        <>  
            <SelectWithDataReactHook name='clientID' control={control} label='Client' options={clientList} style={{width: '34%', marginRight: '1%'}} />

            <ClientContactsAndAddressesHook control={control} watch={watch} setValue={setValue} />

            <SelectWithDataReactHook name='quoteStatusID' control={control} label='Quote Status' options={quoteStatusList} style={{width: '15%'}} />

            {/* End Row 1 */}

            <ReactHookTextField
                name='createdAt'
                type='date'
                control={control}
                label='Quote Date'
                style={{width: '16%' , marginRight: '1%'}} 
            />

            <ReactHookTextField
                name='awardedAt'
                type='date'
                control={control}
                label='Award Date'
                style={{width: '16%' , marginRight: '1%'}} 
            />

            <SelectWithDataReactHook name='salesRepID' control={control} label='Sales Rep' options={salesRepList} style={{width: '19%', marginRight: '1%'}} />

            <SelectWithDataReactHook name='productAreaID' control={control} label='Product Area' options={productAreaList} style={{width: '19%', marginRight: '1%'}} />                                   
                
            <ReactHookTextField
                name='project'
                type='text'
                control={control}
                label='Project'
                style={{width: '26%'}} 
            />
            
            {/* End Row 2 */}

            <SelectWithDataReactHook name='enquirySourceID' control={control} label='Enquiry Source' options={enquirySourceList} style={{width: '19%', marginRight: '1%'}} />                                   
            
            <ReactHookTextField
                name='fanSerial'
                type='text'
                control={control}
                label='Prev Fan Serial'
                style={{width: '19%', marginRight: '1%'}}
            />

            <ReactHookTextField
                name='itemRef'
                type='text'
                control={control}
                label='Item Ref'
                style={{width: '15%', marginRight: '1%'}}
            />

            <ReactHookTextField
                name='attributes.auraJobFileNo'
                type='text'
                control={control}
                label='Aura Job File No'
                style={{width: '15%', marginRight: '1%'}}
            />

            <ReactHookTextField
                name='attributes.nybSalesMemoNo'
                type='text'
                control={control}
                label='NYB Sales Memo No'
                style={{width: '15%', marginRight: '1%'}}
            />
            
            <ReactHookTextField
                name='comments'
                type='textarea'
                control={control}
                label='Comments'
                style={{width: '40%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />

            <SelectWithDataReactHook name='industryID' control={control} label='Quote Industry' options={industryList} style={{width: '19%', marginRight: '1%'}} />  

            <SelectWithDataReactHook name='applicationID' control={control} label='Quote Application' options={applicationList} style={{width: '19%', marginRight: '1%'}} />                                   
        </>
    );
};
