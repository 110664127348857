import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const CreateJob = ({ url }) => {
  let history = useHistory();
  return (
    <Button variant="outlined" color="primary" onClick={() => history.push(url)}>
      Create Job
    </Button>
  );
}

export default CreateJob;