import React, { useEffect } from 'react';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { axiosInstance } from 'App';
import makePost from 'helpers/makePost';
import { useParams, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';


const JobsNew = () => {
    const { quoteID, itemID, revision } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const handleOpen = async () => {
        const response = await makePost({
            url: `/jobs`,
            data: { 
                quoteID: parseInt(quoteID),
                itemID: parseInt(itemID),
                revision
            },
        });

        if(response.data.id) {
            history.push(`/jobs/edit/${response.data.id}`);
        } else {
            dispatch(addFeedback('Error creating new job, please go back and try again.', 'error'));
        }
    };

    const handleClose = () => { 
        history.goBack(1)
    };

    useEffect(() => {
        const fetchQuoteDetails = async () => {
            const result = await axiosInstance.get(`jobs?QuoteID=${parseInt(quoteID)}&ItemID=${parseInt(itemID)}&Revision=${revision}&pageNum=1&pageSize=10`);

            if (result.data.length) {
                dispatch(addFeedback('Job already exists with the same Quote ID, Item ID & Revision. Redirecting back to previous page.', 'error'));
                history.goBack(1)
            } else {
                setOpen(true);
            }
        };

        fetchQuoteDetails();
    }, []);

    return (
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Please confirm this quote is ready to transition into a job."}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                No
                </Button>
                <Button onClick={handleOpen} autoFocus>
                Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JobsNew;
