import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, IconButton, Typography, Divider, Grid, FormControl, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from '../../styles';
import ContentWrapper from 'components/ContentWrapper';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Row from './Row';
import ReactHookTextField from '../../../../components/ReactHookTextField';
import { pdf } from '@react-pdf/renderer';
import PDF from './PDF';
import { saveAs } from 'file-saver';
import { axiosInstance } from 'App';
import NumberFormat from '../../../../components/NumberFormat';
import { QuoteFields, quoteDefaultValues, quoteFormValidation, salesRepInitials } from '../../components/QuoteFields';
import Header from '../Header';
import Files from 'components/Files';
import CreateJob from 'components/CreateJob';
import { useAuth } from 'providers/auth';

export const formValidation = Yup.object().shape({
    ...quoteFormValidation.fields,
    lineItems: Yup.array().of(Yup.object().shape({
        quantity: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        price: Yup.number().transform(value => (isNaN(value) ? undefined : parseFloat(value.toFixed(2)))),
        attributes: Yup.object().shape({
            costPrice: Yup.number().transform(value => (isNaN(value) ? undefined : value)) 
        }),
      })),
    validity: Yup.string().required('Validity required'),
});

const tableStyles = makeStyles({
    tableContainer: {
        paddingTop: '6px',
        flexDirection: 'column', 
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            width: '7px'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .5)',
            boxShadow: '0 0 1px rgba(255, 255, 255, .5)'
        }
    }
});

const Form = ({ quoteData, quoteDetails, itemList, revList, clientList, quoteStatusList, salesRepList, productCategoryList, productAreaList, enquirySourceList, industryList, applicationList }) => {
    const classes = useStyles();
    const tableClasses = tableStyles();
    const dispatch = useDispatch();
    const { userAttr } = useAuth();
    const { quoteID, itemID, revision } = quoteData;

    const [totalCost, setTotalCost] = useState(quoteDetails.lineItems.map(e => e.quantity * e.attributes.costPrice));
    const [totalSell, setTotalSell] = useState(quoteDetails.lineItems.map(e => e.quantity * e.price));
    
    const { handleSubmit, watch, control, setError, setValue, getValues } = useForm({
        resolver: yupResolver(formValidation),
        defaultValues: {
            ...quoteDefaultValues(quoteData),
            validity: quoteDetails.validity !== null ? quoteDetails.validity : "30 Days Nett",
            attributes: {
                ...quoteDetails.attributes,
                deliveryTerm: quoteDetails.attributes && quoteDetails.attributes.deliveryTerm !== null ? quoteDetails.attributes.deliveryTerm : "Ex. Works Melbourne",
                currency: quoteDetails.attributes && quoteDetails.attributes.currency !== null ? quoteDetails.attributes.currency : "AUD GST Excluded",
            },
            lineItems: quoteDetails.lineItems.length ? quoteDetails.lineItems : [{}],
            updatedAt: quoteDetails.updatedAt
        } 
    });
    
    const { fields, append, remove } = useFieldArray({
        control,
        name: "lineItems"
      });

    const calcTotalCost = () => totalCost.reduce((a, b) => {   
        return (a + b);
    }, 0);

    const calcTotalSell = () => totalSell.reduce((a, b) => {   
        return (a + b);
    }, 0);

    const onSubmit = async data => {
        try {
            const response = await makePost({
                url: `/quotedetails/${quoteID}/${itemID}/${revision}/spareparts`,
                data: { 
                    ...data,
                    totalPrice: calcTotalSell(),
                    totalCost: calcTotalCost(),
                    combinedPrice: calcTotalSell(),
                    combinedCost: calcTotalCost()
                },
            });

            if (response.status === 200) {
                setValue('updatedAt', response.data.updatedAt)
            }

            dispatch(addFeedback('Products saved to quote item', 'success'));
        } catch (error) {
            const errData = error.response.data;

            if (errData === "Stale update. Reload and update again") {
                dispatch(addFeedback(errData, 'error'));
            } else {
                Object.keys(errData.errors).map((key) => {
                    setError(key.slice(2), errData.errors[key][0]);
                    return null;
                });
    
                dispatch(addFeedback(errData.title || errData, 'error', errData.errors));
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Header quoteData={quoteData} itemList={itemList} revList={revList} productCategoryList={productCategoryList} getValues={getValues} />

            <ContentWrapper>
                <QuoteFields
                    control={control}
                    clientList={clientList}
                    quoteStatusList={quoteStatusList}
                    salesRepList={salesRepList}
                    productAreaList={productAreaList}
                    enquirySourceList={enquirySourceList}
                    industryList={industryList}
                    applicationList={applicationList}
                    watch={watch} 
                    setValue={setValue} />
            </ContentWrapper>
            
            <Typography variant="h2">
                Aftermarket Parts
            </Typography>

            <ContentWrapper>
                <div className={tableClasses.tableContainer}> 
                    { !fields.length ? 
                        <Button
                            variant='contained'
                            color='primary'
                            type="button"
                            onClick={() => append({})}
                        >
                            Add a Aftermarket Part
                        </Button> :
                        <>
                            {fields.map((field, index) => {
                                return (
                                    <Row key={field.id} control={control} index={index} field={field} setTotalCost={setTotalCost} totalCost={totalCost} setTotalSell={setTotalSell} totalSell={totalSell} remove={remove} /> 
                            )})}
                            
                            <div style={{textAlign: 'right'}}>
                                <IconButton
                                    type="button"
                                    color="secondary"
                                    onClick={() => append({})}
                                    style={{width: '4%'}}
                                    >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </>
                    }
                
                    <Divider />
                    <br />
                
                    <TextField
                        label='Total Cost'
                        type='text'
                        disabled={true}
                        className={classes.disabledInput}
                        variant='outlined' 
                        InputLabelProps={{ shrink: true }} 
                        size='small'
                        value={calcTotalCost()}
                        style={{width: '125px', marginLeft: '835px'}}
                        InputProps={{
                            inputComponent: NumberFormat,
                        }}
                    />

                    <TextField
                        label='Total Sell'
                        type='text'
                        disabled={true}
                        className={classes.disabledInput}
                        variant='outlined' 
                        InputLabelProps={{ shrink: true }} 
                        size='small'
                        value={calcTotalSell()}
                        style={{width: '125px', marginLeft: '145px'}}
                        InputProps={{
                            inputComponent: NumberFormat,
                        }}
                    />
                </div>
            </ContentWrapper>

            <ContentWrapper>
                <ReactHookTextField
                    name='attributes.extraText'
                    type='textarea'
                    control={control}
                    label='Extra Text'
                    style={{width: '100%'}}
                    InputProps={{
                        multiline:true
                    }}
                />

                <ReactHookTextField
                    name='attributes.currency'                    
                    type='text'
                    control={control}
                    label='Currency'
                    style={{width: '24%', marginRight: '1%'}}
                />

                <ReactHookTextField
                    name='validity'
                    type='text'
                    control={control}
                    label='Validity'
                    style={{width: '24%', marginRight: '1%'}}
                />

                <ReactHookTextField
                    name='attributes.leadTime'
                    type='text'
                    control={control}
                    label='Lead Time'
                    style={{width: '24%', marginRight: '1%'}}
                />

                <ReactHookTextField
                    name='attributes.deliveryTerm'
                    type='text'
                    control={control}
                    label='Delivery Term'
                    style={{width: '25%'}}
                />
            </ContentWrapper>
            <Grid container justifyContent="space-between">
                <Grid>
                    { userAttr.roles.includes("Admin") && <>
                        <Typography variant="h1" className={classes.header} style={{ display: 'inline' }}>State: </Typography>
                        <Controller
                            name='quoteStateID'
                            type={'select'}
                            control={control}
                            render={({ field, fieldState: { error } }) =>
                                <FormControl variant='outlined' className={classes.select}>
                                    <Select
                                        native
                                        {...field}
                                        margin='dense'
                                        onChange={(e) => {
                                            return field.onChange(e.target.value)
                                        }}>
                                            <option key='state-1' value='1'>Open</option>
                                            <option key='state-2' value='2'>Archive</option>
                                    </Select>
                                </FormControl>
                            }
                        />
                    </>}
                </Grid>
                <Grid>
                    <Button
                        variant='contained'
                        color='secondary'
                        type='submit'
                    >
                        Save Quote
                    </Button>
                    &nbsp;
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                            const quoteRes = await axiosInstance({ url: `/quotes/${quoteID}/${itemID}/${revision}` });
                            const quoteDetailsRes = await axiosInstance({ url: `/quotedetails/${quoteID}/${itemID}/${revision}/spareparts` });
                            const quoteData = quoteRes.data;
                            
                            const blob = await pdf((
                                <PDF quoteData={quoteData} quoteDetails={quoteDetailsRes.data} />
                            )).toBlob();

                            const initials = quoteData.salesRepName ? salesRepInitials(quoteData.salesRepName) : 'NoSalesRep';
                                
                            saveAs(blob, `${quoteID}${revision}${itemID}${initials}.pdf`);
                        }}
                    >
                        Generate PDF 
                    </Button>
                    &nbsp;
                    <CreateJob url={`/jobs/new/${quoteID}/${revision}/${itemID}`} />
                </Grid>
            </Grid>
            <br/>

            <Typography variant="h2">
                Files
            </Typography>

            <ContentWrapper style={{display: 'flex'}}>
                <Files url={`/quotes/${quoteID}/${itemID}/${revision}/files`} />
            </ContentWrapper>
        </form>
    );
};

export default Form;
