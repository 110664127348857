import React, { useEffect, useState } from 'react';
import List from './List';
import { axiosInstance } from 'App';

const ListWrapper = ({ productAreaList }) => {
    const [salesRepList, setSalesRepList] = useState();
    const [jobStatusList, setJobStatusList] = useState();
    const [enquirySourceList, setEnquirySourceList] = useState();
    const [jobStateList, setJobStateList] = useState();
    const [industryList, setIndustryList] = useState();
    const [applicationList, setApplicationList] = useState();

    useEffect(() => {
        const fetchSalesList= async () => {
            const result = await axiosInstance({ url: '/users?role=Sales' });

            const options = result.data.map(({ id, givenName, familyName, enabled }) => ({
                label: givenName + ' ' + familyName,
                id: id,
                group: enabled ? "Active Users" : "Inactive Users"
            })).sort((a, b) => a.label.localeCompare(b.label)).sort((a, b) => a.group.localeCompare(b.group))

            setSalesRepList(options);
        };

        fetchSalesList();

        const fetchJobStatusList= async () => {
            const result = await axiosInstance({ url: '/refdata/JobStatus' });

            const options = result.data
            .filter(({displayOrder}) => displayOrder > 0)
            .map(({ id, data }) => ({
                label: data,
                id: id
            }));

            setJobStatusList(options);
        };

        fetchJobStatusList();

        const fetchEnquirySourceList= async () => {
            const result = await axiosInstance({ url: '/refdata/EnquirySource' });

            const options = result.data.map(({ id, data }) => ({
                label: data,
                id: id
            }));

            setEnquirySourceList(options);
        };

        fetchEnquirySourceList();

        const fetchJobStateList = async () => {
            const result = await axiosInstance({ url: '/refdata/JobState' });

            const options = result.data
            .filter(({displayOrder}) => displayOrder > 0)
            .map(({ id, data }) => ({
                label: data,
                id: id
            }));
            
            setJobStateList(options);
        };

        fetchJobStateList();

        const fetchIndustryList= async () => {
            const result = await axiosInstance({ url: '/refdata/Industry' });

            const options = result.data.map(({ id, data }) => ({
                label: data,
                id: id
            }));

            setIndustryList(options);
        };

        fetchIndustryList();

        const fetchApplicationList= async () => {
            const result = await axiosInstance({ url: '/refdata/Application' });

            const options = result.data.map(({ id, data }) => ({
                label: data,
                id: id
            }));

            setApplicationList(options);
        };

        fetchApplicationList();
    }, []);

    const isLoading = !(salesRepList && jobStatusList && enquirySourceList && jobStateList && industryList && applicationList);

    return <List isLoading={isLoading} salesRepList={salesRepList} jobStatusList={jobStatusList} productAreaList={productAreaList} enquirySourceList={enquirySourceList} jobStateList={jobStateList}  industryList={industryList}  applicationList={applicationList} />;
};

export default ListWrapper;
